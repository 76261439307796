.container {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
.switch-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 0px !important;
}
