@import "../style/color.module.scss";
.common-style {
  width: fit-content;
  padding: 4px 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 38px;
}
.submit-btn {
  @extend .common-style;
  background-color: $primary;
  color: #ffffff;
  opacity: 0.8;
}

.submit-btn:hover {
  opacity: 1;
  background-color: $primary;
}
.cancel-btn {
  @extend .common-style;
  background-color: #80808087;
  color: #000;
  &:hover {
    opacity: 0.8;
  }
}
.wrap-submit-cancel {
  width: 230px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
