$black-color: #192746;
$hover-background: #334469;
$darkBlue: #2e3a6c;
$lightBlue: #3c488d;
$whiteColor: #fff;

$primary: #007bff;
$primary-hover: transparent linear-gradient(247deg, #3d8dfa 0%, #005bd8 100%) 0%
  0% no-repeat padding-box;
$primary-hover-boxShadow: 0px 4px 10px #00000038;
$primary-text: #2706de;

$green: #28a745;
$green-border: #28a745;
$green-hover: #218838;
$green-hover-boxShadow: 0px 4px 10px #15d84538;
$green-text: #1ae238;

$astric-color: red;

$secondary: #c0c5ca;
$secondary-hover: #5a6268;
$secondary-border: #545b62;
$secondary-text: #fff;
