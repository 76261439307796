@import "../style/color.module.scss";
.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  margin: auto;
  margin-top: 24px;
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    span {
      color: $astric-color;
    }
  }

  input,
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
  :global(.ant-picker-range) {
    width: 100%;
  }
  :global(.ant-picker-input) {
    width: 100%;
    input {
      margin-bottom: 0px;
      height: 40px;
    }
  }

  :global(.ant-upload-wrapper) {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
  :global(.ant-upload-list-item) {
    margin-left: 16px;
    margin-top: 0px !important;
  }
  :global(.ant-select),
  :global(.ant-input-number) {
    // margin-bottom: 24px;
    width: 100%;
    height: 40px;
  }
  h1 {
    width: 100%;
    text-align: center;
    line-height: 0;
    margin-bottom: 42px;
  }
}
.warp-search-inp {
  display: flex;
  padding: 8px 0px 24px;
  justify-content: space-between;
  :global(.ant-input) {
    height: 28px !important;
  }
  :global(.ant-btn) {
    height: 38px !important;
  }
}
.inp-container-full {
  width: 100%;
  margin-bottom: 24px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
    padding: 8px;
    label {
      font-size: 12px;
    }
  }
  .inp-container {
    width: 48%;
  }
}
