.wrap-img-name {
  display: flex;
  align-items: center;
  color: #000;
  justify-content: space-between;
  svg {
    margin-left: 6px;
    cursor: pointer;
  }
}
.name {
  font-size: 16px;
  font-weight: 500;
  color: #1677ff;
}
.heading {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  opacity: 0.6;
}
.drawer-heading {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0px 6px;
  opacity: 1;
}
.drawer-title {
  font-size: 14px;
  font-weight: 600;
  margin: 4px 0px;
  opacity: 0.6;
}

.sub-title {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin: 4px 0px;
  opacity: 0.6;
}
.sub-title-value {
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0px;
  opacity: 0.6;
}
.value {
  font-size: 16px;
  font-weight: 400;
  margin: 4px 0px;
}

.edit-delete-update-img {
  cursor: pointer;
  margin-left: 10px;
  img {
    width: 4px;
  }
}
.hr-line {
  opacity: 0.2;
}

.edit-delete-update-wrap {
  position: absolute;
  z-index: 2;
  right: 55px;
  width: 124px;
  background: #ffffff;
  box-shadow: 0px 4px 10px #12121233;
  border-radius: 8px;
  padding: 4px 0px;
  :global(.ant-divider-horizontal) {
    margin: 8px 0;
  }
  .arrow {
    position: relative;
    left: 78px;
    top: -6px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .text {
    padding-left: 18px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    color: #292929;
  }
  .blue-text {
    @extend .text;
    color: blue;
  }
}
.tabs-and-daterange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.otp-drawer{

}
