@import "../style/color.module.scss";
.days-container {
  display: flex;
  align-items: center;
  width: 249px;
  justify-content: space-between;
}
.days-item {
  font-size: 11px;
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.5294117647);
}
.selected-day {
  @extend .days-item;
  background-color: $primary;
  color: #fff;
}
