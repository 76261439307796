.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}
.borderBottom {
  border-bottom: 1px solid #e5e7eb; /* Tailwind's gray-200 */
  margin-bottom: 1rem; /* spacing below the border */
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.radioInput {
  height: 16px !important;
  width: 16px !important;
  margin-bottom: 0px !important;
  accent-color: #2563eb; /* Tailwind's blue-600 */
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.wrap-inp {
  display: flex;
  align-items: center;
}
.label {
  margin-bottom: 0px !important;
}

.price {
  font-size: 1rem; /* adjust as needed */
  margin-left: 1rem; /* spacing for price */
}
