.main-container {
  // height: 100vh;
  background: transparent !important;
  :global(.ant-layout-sider) {
    position: fixed !important;
    height: 100vh;
    z-index: 205;
    width: 60px !important;
    max-width: unset !important;
    min-width: unset !important;
    top: 55px;
    &:hover {
      width: 220px !important;
    }
    :global(.ant-menu-item) {
      svg {
        fill: #fff;
      }
    }
  }
  .not-collapsed {
    display: block;
  }
  .collapsed {
    display: block;
  }
  :global(.ant-layout-content) {
    margin-top: 60px;
    padding: 24px;
    margin-left: 60px;
  }
  :global(.ant-empty) {
    border-radius: 6px;
    border: 1px solid #80808030;
    padding: 24px;
    margin-inline: 0px !important;
  }
  :global(.ant-layout-header) {
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 200;
  }
  .left-content {
    // padding-left: 60.3px;
    background: transparent !important;
  }
  :global(.ant-layout-sider-children) {
    margin-top: 16px;
  }
  :global(.ant-menu-title-content) {
    font-size: 17px;
  }
  :global(.ant-menu-item) {
    height: 48px !important;
    padding-left: 12px !important;
    svg {
      width: 24px;
    }
  }
  :global(.ant-menu-item-icon) {
    font-size: 22px !important;
  }
}

.wrap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0px 24px;
  background: #001529;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .main-container {
    .left-content {
      padding-left: 0px;
    }
    :global(.ant-layout-sider) {
      width: 200px !important;
    }
    :global(.ant-layout-content) {
      padding: 8px;
      margin-left: 0px;
    }
    .not-collapsed {
      display: none;
    }
  }
}
