.dropdownContainer {
  width: 20%;
  position: relative;
  cursor: pointer;
}

.dropdownButton {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;
}

.dropdownMenu {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  z-index: 30;
  ul {
    padding-left: 0px !important;
    margin: 0px;
    list-style-type: none;
  }
}

.dropdownItem {
  border-radius: 4px;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdownItem:hover {
  background: linear-gradient(to bottom right, #60a5fa, #3b82f6, #1e40af);
  color: white;
}
