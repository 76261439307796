@import "../style/color.module.scss";
.container {
  :global(.ant-table-row-expand-icon) {
    background-color: $primary;
    color: #fff;
    transform: scale(1.3);
  }
  :global(.ant-table-tbody) {
    :global(.ant-table-cell) {
      padding: 16px;
    }
  }

  :global(.ant-table-container) {
    border-inline-start: none !important;
    border-top: none !important;
    :global(table) {
      margin: auto;
      border: 1px solid #f0f0f0;
    }
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    :global(.ant-table-tbody) {
      :global(.ant-table-cell) {
        padding: 8px;
      }
    }
    :global(.ant-table-container) {
      :global(table) {
        min-width: 100% !important;
      }
    }
    :global(.ant-table-thead) {
      :global(.ant-table-cell) {
        padding: 8px;
      }
    }
  }
}
