@import "../style/color.module.scss";
.secondary {
  border-radius: 4px;
  padding: 0px 16px;
  height: 38px;
  min-width: 110px;
  font-family: "Courier New", Courier, monospace;
  border: none;
  background-color: $secondary;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: $secondary-hover;
    color: #fff;
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.primary {
  border-radius: 4px;
  padding: 0px 16px;
  border: none;
  height: 38px;
  min-width: 110px;
  background-color: $primary;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: $primary-hover-boxShadow;
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.green {
  border-radius: 4px;
  padding: 0px 16px;
  border: none;
  height: 38px;
  min-width: 110px;
  background-color: $green;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: $green-hover;
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}
