.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
}

.borderBottom {
  border-bottom: 1px solid #e5e7eb; /* Tailwind's gray-200 */
  margin-bottom: 1rem; /* equivalent to spacing below the border */
}

.list {
  display: flex;
  // flex-direction: column;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* equivalent to space-x-2 */
  width: 100%;
  height: 30px;
}

.radioInput {
  height: 16px !important;
  width: 16px !important; /* equivalent to w-4 */
  accent-color: #2563eb; /* Tailwind's blue-600 */
  margin: 0px !important;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.extraSmallTextBlack {
  font-size: 14px;
  font-weight: 500;
}
