@import "../style/color.module.scss";
.container {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  :global(.ant-typography) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.left-container {
  font-size: 24px;
  letter-spacing: 0px;
  color: #fff;
  cursor: pointer;
}
.middle-container {
  text-align: center;
}
.right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.client-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
}
.client-title {
  font-size: 11px;
  color: #fff;
  line-height: 0.8;
}
.logo-img {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.antd-user-outline {
  font-size: 24px;
  margin-right: 12px;
}
.wrap-img {
  height: 50px;
  svg {
    path {
      fill: #fff;
    }
  }
}
.client-name-container {
  height: 50px;
  color: #fff;
  display: table-column;
  letter-spacing: 0px;
}
.menu-container {
  width: 200px;
  padding: 16px 8px;
  background-color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1803921569);
  position: absolute;
  right: 61px;
  top: 55px;
  z-index: 2;
}
.items {
  width: 184px;
  background-color: $whiteColor;
  font-size: 15px;
  color: $black-color;
  height: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    color: $whiteColor;
    background-color: #001529;
  }
}
.drop-down-icon {
  cursor: pointer;
  margin-left: 16px;
  fill: #fff;
}
.select-box {
  height: 35px;
  margin-top: 14px;
  min-width: 250px;
}
