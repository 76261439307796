.container {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* equivalent to space-y-4 */
}

.title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
}
.wrap-header {
  width: 100%;
  gap: 4px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.first-col {
  width: 30%;
  display: flex;
  align-items: center;
  input {
    width: 16px;
  }
  label {
    font-size: 16px;
    margin-top: 4px;
    margin-left: 6px;
  }
}

.second-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.borderBottom {
  border-bottom: 1px solid #e5e7eb; /* Tailwind's gray-200 */
  margin-bottom: 1rem; /* spacing below the border */
}

.wrap-first-second-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}
