.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background: #fff;
  // margin-top: -50px;
}
.heading {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-top: -8px;
}

.glass-form {
  border-radius: 10px;
  padding: 40px 32px;
  background-image: linear-gradient(to bottom, #3b82f6, #2563eb, #1d4ed8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.wrap-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 8px;
  color: #fff;
  text-align: left;
}

.form-input {
  padding: 0px 8px;
  margin-bottom: 24px;
  border: none;
  border-radius: 5px;
  height: 40px;
}

.forget-password {
  color: #fff;
  text-decoration: none;
  margin: -16px 0px 24px;
  text-align: right;
}

.form-button {
  border: none;
  border-radius: 5px;
  background: #010101ad;
  color: #fff;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-button:hover {
  background: #000;
}
.switch-mode {
  margin-top: 8px;
  text-align: end;
  color: #fff;
  font-size: 16px;
  span {
    cursor: pointer;
    font-weight: 600;
  }
}
/* Media queries for responsiveness */

@media only screen and (max-width: 600px) {
  .glass-form {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .glass-form {
    width: 100%;
  }
}
