@import "../style/color.module.scss";
.filter-container {
  margin-bottom: 24px;
  width: 100%;
  :global(.ant-select) {
    width: 200px;
  }
  :global(.ant-space) {
    :global(.ant-space-item) {
      width: 20%;
    }
  }
}
:global(.ant-picker) {
  height: 32px;
}
.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 750px;
  margin: auto;
  margin-top: 0px;

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    span {
      color: $astric-color;
    }
  }

  input,
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #80808052;
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    // margin-bottom: 24px;
  }
  :global(.ant-upload-wrapper) {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
  :global(.ant-upload-list-item) {
    margin-left: 16px;
    margin-top: 0px !important;
  }
  :global(.ant-select) {
    // margin-bottom: 24px;
    width: 100%;
    height: 40px;
  }
  :global(.ant-picker) {
    height: 40px;
    width: 100%;
  }
  :global(.ant-input-number) {
    width: 100%;
    border: 1px solid #80808052;
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    // margin-bottom: 24px;
  }
  h1 {
    width: 100%;
    text-align: center;
    line-height: 0;
    margin-bottom: 42px;
  }
}
.wrap-inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inp-container {
  width: 45%;
  margin-bottom: 24px;
}
.inp-container-full {
  width: 100%;
  margin-bottom: 24px;
}
.wrap-disable {
  // padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  :global(.ant-switch) {
    margin-left: 8px;
  }
  .title {
    width: max-content;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
  }
}
.wrap-price {
  // width: 690px;
  justify-content: space-between;
  // padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .price-conatiner {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 16px;
    .title {
      width: max-content;
      font-size: 14px;
      font-weight: 500;
      margin-right: 8px;
    }
    .limit-title {
      width: 80px;
      margin-left: 16px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.weekend-disable-price {
  width: 100%;
  margin: 24px 0px;
}
.day-inp-container {
  width: 100%;
  padding: 24px;
  border: 1px solid #80808052;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 24px 0px;
  background: #efeaea4d;
}
.add-more {
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: -10px;
}
.warp-search-inp {
  display: flex;
  padding: 8px 0px 24px;
  justify-content: space-between;
  :global(.ant-input) {
    height: 28px !important;
  }
  :global(.ant-btn) {
    height: 38px !important;
  }
}
.create-station-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid #80808052;
}
.wrap-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-container {
  background-color: #ece9e9;
  padding: 8px;
  border-radius: 8px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
    padding: 8px;
    label {
      font-size: 12px;
    }
  }
  .inp-container {
    width: 48%;
  }
}

@media only screen and (max-width: 600px) {
  .ant-picker {
    width: 100%;
  }
}
